// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { CognitoIdentityProviderClient, InitiateAuthCommand, GetUserCommand, AuthFlowType, GlobalSignOutCommand, InitiateAuthCommandInput, ForgotPasswordCommand, ConfirmForgotPasswordCommand } from "@aws-sdk/client-cognito-identity-provider";
import config from "../config.json";
import jwtDecode, { JwtPayload } from "jwt-decode";

export const cognitoClient = new CognitoIdentityProviderClient({
  region: config.region,
});


type AuthFlowLocal = AuthFlowType;
const authFlow: AuthFlowLocal = "USER_PASSWORD_AUTH";
export const signIn = async (username: string, password: string) => {


  const params = {
    AuthFlow: authFlow,
    ClientId: config.clientId,
    AuthParameters: {
      USERNAME: username,
      PASSWORD: password,
    },
  };
  try {
    const command = new InitiateAuthCommand(params);
    const { AuthenticationResult } = await cognitoClient.send(command);

    if (AuthenticationResult) {
      sessionStorage.setItem("idToken", AuthenticationResult.IdToken || '');
      sessionStorage.setItem("accessToken", AuthenticationResult.AccessToken || '');
      sessionStorage.setItem("refreshToken", AuthenticationResult.RefreshToken || '');

      const userName = await getUserName(AuthenticationResult.AccessToken);
      console.log("🚀 ~ signIn ~ userName:", userName);
      sessionStorage.setItem("user_id", userName || '');

      return AuthenticationResult;
    }
  } catch (error) {
    console.error("Error signing in: ", error);
    throw error;
  }
};
export const getUserName = async (accessToken: string) => {
  const input = { // GetUserRequest
    AccessToken: accessToken,
  };
  const command = new GetUserCommand(input);
  const response = await cognitoClient.send(command);
  console.log("🚀 ~ getUser ~ response:", response)
  await setUserAttributes(response.Username);
  return response.Username;

};

export const setUserAttributes = async (userName: string) => {
  // FIXME: fix types for userAttr
  const userAttr: any = await fetchUser(userName);

  const dashboards = await getAllEmbeddedDashboards();
  console.log("🚀 ~ setUserAttributes ~ userAttr:", userAttr)
  sessionStorage.setItem("given_name", userAttr?.FirstName || '');
  sessionStorage.setItem("family_name", userAttr?.LastName || '');
  sessionStorage.setItem("company_id", userAttr?.CompanyID || '');
};

//Retieve user details from API
const fetchUser = async (userName: string) => {
  console.log("fetch user ", userName);
  // const headers = {
  //   'Accept': 'application/json',
  //   'Content-Type': 'application/json',
  //   'Authorization': `Bearer ${sessionStorage.getItem("accessToken")}`,

  // }
  const headers = await getHeaders();
  // FIXME: should the following URL come from env variable?
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/user/${userName}`, {
      mode: 'cors',
      method: 'GET',
      headers: headers,
    });
    const data = await response.json();

    console.log("🚀 ~ fetchUser ~ response:", data);
    return data;
  } catch (error) {
    console.error("Error:", error);
  }

};

export const signOut = async () => {
  const token = sessionStorage.getItem("accessToken");
  try {
    await cognitoClient.send(new GlobalSignOutCommand({ AccessToken: token }));
  } catch (err) {
    console.error("Error logging out!");
  }
};


export const validateAccessToken = async (accessToken: string) => {
  try {
    const decodedToken = jwtDecode<JwtPayload>(accessToken);
    if (decodedToken && typeof decodedToken === 'object') {
      const { exp } = decodedToken;
      const currentTime = Math.floor(Date.now() / 1000);
      if (exp && currentTime <= exp) {
        // Token is valid and not expired
        return true;
      }
    }
    // Token is invalid or expired
    return false;
  } catch (error) {
    console.error('Error verifying access token:', error);
    throw error;
  }
};

export const refreshAccessToken = async () => {
  try {
    const refreshToken = sessionStorage.getItem("refreshToken");
    const params: InitiateAuthCommandInput = {
      AuthFlow: "REFRESH_TOKEN_AUTH",
      ClientId: config.clientId,
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
    };
    const command = new InitiateAuthCommand(params);
    const { AuthenticationResult } = await cognitoClient.send(command);
    if (AuthenticationResult) {
      sessionStorage.setItem("accessToken", AuthenticationResult.AccessToken || '');
      sessionStorage.setItem("refreshToken", AuthenticationResult.RefreshToken || '');
      return AuthenticationResult.AccessToken;
    }
  }
  catch (error) {
    console.error("Error refreshing token");
  }
};

export async function getHeaders(): Promise<HeadersInit> {
  try {
    //console.log("localstorage jwt: ", window.localStorage.getItem("jwt"))
    let accessToken = sessionStorage.getItem("accessToken");
    const validJwt = await validateAccessToken(accessToken);
    if (!validJwt) {
      accessToken = await refreshAccessToken();
    }
    const headers: HeadersInit = {
      'Accept': 'application/json',
      'Content-Type': 'text/plain',
      'Authorization': `Bearer ${accessToken}`,
      // 'x-api-peakrev-uid': window.localStorage.getItem("user_id") || "",
      // 'x-api-peakrev-cid': window.localStorage.getItem("company_id") || ""
    }

    return headers;
  } catch (error) {
    console.error('Error getting Headers:', error);
    throw error;
  }
}

export const getAllEmbeddedDashboards = async () => {
  const headers = await getHeaders();
  try {
    const fetchedPost = await fetch(`${process.env.REACT_APP_API_URL}/analytics/getAllDashboards`, {
      mode: 'cors',
      method: 'get',
      headers: headers,
    });
    const data = await fetchedPost.json();
    console.log("embedded dashboards ", data);
    sessionStorage.setItem("dashboards", JSON.stringify(data));
    return data;
  } catch (error) {
    console.error("Error:", error);
    return "Error getting embedded dashboards";
  }
}

export const forgotPassword = async (username: string) => {
  const input = { // ForgotPasswordRequest
    ClientId: config.clientId, // required

    Username: username, // required
  };
  const command = new ForgotPasswordCommand(input);
  const response = await cognitoClient.send(command);
  return response;
};

export const confirmPassword = async (username: string, verificationCode: string, newPassword: string) => {
  const params = {
    ClientId: config.clientId,
    ConfirmationCode: verificationCode,
    Password: newPassword,
    Username: username,
  };

  try {
    const command = new ConfirmForgotPasswordCommand(params);
    const response = await cognitoClient.send(command);
    return response;
  } catch (error) {
    console.error("Error confirming password: ", error);
    throw error;
  }
};



