import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Grid } from '@mui/material';
import { WidthFull } from '@mui/icons-material';
import React from 'react';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

interface PaceReportData {
  Title: string;
  report: Array<{
    propertyId: number;
    propertyName: string;
    current: Record<string, number>;
    previous: Record<string, number>;
  }>;
}

const PaceReportBarChart = ({ data }: { data: PaceReportData }) => {
  // Extract the report data
  
  const { Title: reportTitle, report } = data;

  const charts = report.map((property, index) => {
    const { propertyName, current, previous } = property;

    // Define datasets for each chart
    const datasets = [
      {
        label: 'Reservations & Nights',
        labels: ['Reservations', 'Nights'],
        currentData: [current.reservation, current.nights],
        previousData: [previous.reservation, previous.nights],
      },
      {
        label: 'Gross & Net Revenue',
        labels: ['Gross Revenue', 'Nett Revenue'],
        currentData: [current.grossRevenue, current.nettRevenue],
        previousData: [previous.grossRevenue, previous.nettRevenue],
      },
      {
        label: 'Average Daily Rates',
        labels: ['Gross ADR', 'Nett ADR'],
        currentData: [current.averageDailyRateGross, current.averageDailyRateNett],
        previousData: [previous.averageDailyRateGross, previous.averageDailyRateNett],
      },
      {
        label: 'Occupancy Percentage',
        labels: ['Occupancy %'],
        currentData: [current.occupancyPercent],
        previousData: [previous.occupancyPercent],
      },
    ];

    return (
      datasets.map((dataset, chartIndex) => {
      const chartData = {
        labels: dataset.labels,
        datasets: [
          {
            label: 'Current',
            data: dataset.currentData,
            backgroundColor: 'rgba(54, 162, 235, 0.6)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
          },
          {
            label: 'Previous',
            data: dataset.previousData,
            backgroundColor: 'rgba(255, 99, 132, 0.6)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
          },
        ],
      };

      const options = {
        borderRadius: 4,
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: `${dataset.label}`,
            font: {
              size: 20
            }
          },
          legend: {
            position: 'top',
          },
          datalabels: {
            color: 'blue',
            formatter: function(value: any, context: { chart: { data: { labels: { [x: string]: any; }; }; }; dataIndex: string | number; }) {
              const addPrefixSuffix = (value: any) => {
                if (context.chart.data.labels[0].includes('Gross') || context.chart.data.labels[0].includes('Nett')) {
                  return `$${Number(value).toLocaleString()}`;
                }else if (context.chart.data.labels[0].includes('%')) {
                  return `${value.toFixed(2)}%`;
                }
                return value;
              }
              return addPrefixSuffix(value);
            },
            labels: {
              title: {
                font: {
                  weight: 'bold'
                }
              },
              value: {
                color: 'green'
              }
            }
          }
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Metrics',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Values',
            },
          },
        },
      };

      return (
          <div key={`${index}-${chartIndex}`} style={{ marginBottom: '2rem',height: '400px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>  
            <Bar data={chartData} options={options} />
          </div>

      );
    }));
  });

  return (
    <div >{charts.flat()}</div>
  );
};

// const PaceReportBarChart = ({ data }: { data: PaceReportData }) => {
//   // Extract the report data
//   const { Title: reportTitle, report } = data;

//   // Define bar chart data and options for each property
//   const charts = report.map((property, index) => {
//     const {
//       propertyName,
//       current,
//       previous,
//     } = property;

//     const labels = Object.keys(current);

//     // Prepare chart datasets
//     const chartData = {
//       labels,
//       datasets: [
//         {
//           label: 'Current',
//           data: Object.values(current),
//           backgroundColor: 'rgba(54, 162, 235, 0.6)',
//           borderColor: 'rgba(54, 162, 235, 1)',
//           borderWidth: 1,
//         },
//         {
//           label: 'Previous',
//           data: Object.values(previous),
//           backgroundColor: 'rgba(255, 99, 132, 0.6)',
//           borderColor: 'rgba(255, 99, 132, 1)',
//           borderWidth: 1,
//         },
//       ],
//     };

//     const options = {
//       responsive: true,
//       plugins: {
//         title: {
//           display: true,
//           text: `${propertyName} - ${reportTitle}`,
//         },
//         legend: {
//           position: 'top',
//         },
//       },
//       scales: {
//         x: {
//           title: {
//             display: true,
//             text: 'Metrics',
//           },
//         },
//         y: {
//           title: {
//             display: true,
//             text: 'Values',
//           },
//         },
//       },
//     };

//     return (
//       <div key={index} style={{ marginBottom: '2rem' }}>
//         <Bar data={chartData} options={options} />
//       </div>
//     );
//   });

//   return <div>{charts}</div>;
// };

export default React.memo(PaceReportBarChart);

