/**
PEAK REVEUNE
*/

// @mui material components
import Card from "@mui/material/Card"
import Typography from "@mui/material/Typography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// Data
import React, { useState, useEffect } from 'react';
//import Button from "assets/theme/components/button";
import Link from "@mui/material/Link";

import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Switch, Table } from '@mui/material';

import { getHeaders } from "../../../../auth/authService";

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import PaceReportBarChart from "./pacechart";
import config from "../../../../config.json";


const Report: React.FC = () => {  

  const [propertyData, setPropertyData] = useState<any>([])
  const [startDisplayDate , setStartDisplayDate] = useState<any>(null)
  const [endDisplayDate , setEndDisplayDate] = useState<any>(null)
  const [propertyId, setPropertyId] = useState('');
  const [compareTo, setCompareTo] = useState('lastYear');
  const [groupBy, setGroupBy] = useState('none');
  const [paceData, setPaceData] = useState<any>('')
  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {

    //Retrieve all properties from API
    const fetchProperties = async () => {
      
      const headers = await getHeaders();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/properties/${sessionStorage.getItem("company_id")}`, {
        mode: 'cors',
        method: 'get', 
        headers: headers,
      })
      .then((response) => response.json())
      .then((data) => {
        setPropertyData(data)
      })
      .catch((error) => {
        console.error("Error:", error)
      })
    }

    fetchProperties()

  },[])

  const propertySelect = (
    <FormControl sx={{ m: 1, minWidth: 120, minHeight: 50 }}>
      <InputLabel id="property-label">Property</InputLabel>
      <Select
        sx={{ width: '100%', minWidth: 180,minHeight: 40 }}
        labelId="property-label"
        id="property-select-helper"
        label="Property"
        value={propertyId}
        onChange={handlePropertyChange}
      >
        {propertyData && propertyData.map((item: any,key: number)=> (
          <MenuItem value={item.id}>{item.name}</MenuItem>
        ))} 
      </Select>
    </FormControl>
  )

  function handlePropertyChange(event: SelectChangeEvent<any>): void {
    setPropertyId(event.target.value);
  }
  
  function handleCompareToChange(event: SelectChangeEvent<any>): void {
    setCompareTo(event.target.value);
  }

  function handleGroupByChange(event: SelectChangeEvent<any>): void {
    setGroupBy(event.target.value);
  }

  const compareToSelect = (
    <FormControl sx={{ m: 1, minWidth: 120, minHeight: 50 }}>
      <InputLabel id="compareto-label">Compare To</InputLabel>
      <Select
        sx={{ width: '100%', minWidth: 180,minHeight: 40 }}
        labelId="compareto-label"
        id="compareto-select-helper"
        value={compareTo}
        label="Compare To"
        onChange={handleCompareToChange}
        autoWidth
      >
        <MenuItem value='lastYear'>Last Year</MenuItem>
        <MenuItem value='yearBeforeLast'>Year before last</MenuItem>
        <MenuItem value='lastYearAndYearBeforeLast'>Last Year & Year before last</MenuItem>
        <MenuItem value='noComparison'>None</MenuItem>
      </Select>
    </FormControl>
  )

  const groupBySelect = (
    <FormControl sx={{ m: 1, minWidth: 120, minHeight: 50 }}>
      <InputLabel id="groupby-label">Group By</InputLabel>
      <Select
        sx={{ width: '100%', minWidth: 180,minHeight: 40 }}
        labelId="groupby-label"
        id="groupby-select-helper"
        value={groupBy}
        label="Group By"
        onChange={handleGroupByChange}
        autoWidth
      >
        <MenuItem value='none'>None</MenuItem>
        <MenuItem value='category'>Room Category</MenuItem>
      </Select>
    </FormControl>
  )

  async function generateReport (): Promise<void> {
    //check for valid values in all fields
    if (!propertyId || !startDisplayDate || !endDisplayDate) {
      setErrorMsg("Please fill in all fields");
      return;
    }else{
      setErrorMsg("");
    }
    const headers = await getHeaders();
    const body = {
      "propertyId": propertyId,
      "compareTo": compareTo,
      "groupBy": groupBy,
      "dateFrom": startDisplayDate,
      "dateTo": endDisplayDate
    }
    const response = await fetch(`${process.env.REACT_APP_API_URL}/rms/pace`, {
      mode: 'cors',
      method: 'POST', 
      headers: headers,
      body: JSON.stringify(body)
    })
    .then((response) => {
      if (!response.ok) {
        // Handle HTTP errors
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      console.log("Data:", data);
      setPaceData(data);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      // Handle the error appropriately in your UI
    });

  }

  return (
    <DashboardLayout>

    <Grid container>
      <Grid item lg={12} sm={12}>
        <Box p={2}>
          <Typography variant="h5" fontWeight="medium">
            Pace Report 
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={12} sm={12}>
        <Grid container>
          <Grid item lg={3} sm={2} px={1}>
            <Card>
              <Box p={2}>
                <Grid container py={1}>
                  <Grid item lg={12} sm={12}>
                    {propertySelect}
                  </Grid>
                  <Grid item lg={12} sm={12}> 
                    <Box p={1}>
                      <DatePicker 
                        format="DD-MM-YYYY"
                        label="From"
                        value={dayjs(startDisplayDate)}
                        onChange={(newValue) => setStartDisplayDate(newValue)}
                      />
                    </Box>
                  </Grid> 
                  <Grid item lg={12} >
                      <Box p={1}>
                        <DatePicker 
                          format="DD-MM-YYYY"   
                          label="To"
                          value={dayjs(endDisplayDate)}
                          onChange={(newValue) => setEndDisplayDate(newValue)}
                        />
                      </Box>
                  </Grid>   
                  {/* <Grid item lg={12} sm={12}>
                    {compareToSelect}
                  </Grid>  
                  <Grid item lg={12} sm={12}>
                    {groupBySelect}    
                  </Grid>  */}
                  <Grid item lg={12} sm={12}>
                    <div style={{padding: 8, justifyContent: 'center'}}>
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: 'cornflowerblue', color: 'ButtonText' }}
                        onClick={() => generateReport()}
                      >
                        Generate Report
                      </Button>
                    </div>
                  </Grid>
                  <Grid item lg={12} sm={12}>
                    <Typography variant="h4" color="error" p={4}>{errorMsg}</Typography>
                  </Grid>
                </Grid>
                
                
              </Box>
            </Card>
          </Grid>
          <Grid item lg={9} sm={10} px={1}>
            <Card>
              <Grid container>
                <Grid item lg={12} alignContent={'center'}>
                  {paceData ? <Typography variant="h3" fontWeight="medium" p={2}>{paceData.report[0].propertyName}</Typography> : null}
                  {paceData ? <PaceReportBarChart data={paceData} /> : null}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        
      </Grid>
    </Grid>


      <Footer />
    </DashboardLayout>
  );
}

export default Report;



